.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bg {
  background-color: #f0f0f0; /* Light gray background */
  height: 100%; /* Full-height */

}

.container {
  border-radius: 10px; /* Rounded corners for the container */
  background-color: #fff; /* White background for the content */
  padding: 30px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.title {
  margin-bottom: 20px;
  font-size: 32px;
  color: #333; /* Darker text color */
}
body {
  background-color: #222; /* Dark theme background color */
  margin: 0; /* Remove any default margin */
/*}use ubuntu font*/
    font-family: 'Ubuntu', sans-serif;
    }
.error {
  /* Add any styles you want for the heading */
}

.my-custom-tada {
  animation: tada-loop infinite 7s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes tada-loop { /* Animation definition */
  from {
    transform: scale(1) rotateZ(0deg);
  }

  10%, 20%, 30%, 50%, 70%, 80%, 90% {
    transform: scale(0.9) rotateZ(-2deg);
  }

  40%, 60% {
    transform: scale(1.1) rotateZ(10deg);
  }

  to {
    transform: scale(1) rotateZ(360deg);
  }
}
