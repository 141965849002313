/* Styles for the body element */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    color: #333; /* Sets the text color to a dark gray */
    padding: 20px;
}

/* Styles for headings (h1, h2, h3) */
h1, h2, h3 {
    color: blue; /* Sets the text color to blue */
    margin-bottom: 15px; /* Adds a margin below the headings */
}

/* Styles for a specific class named "box" */
.box {
    border: 1px solid black; /* Adds a black border */
    padding: 10px; /* Adds padding inside the box */
    background-color: #eee; /* Sets the background color */
    color: #333;
}
